var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'box-open',"title":_vm.item.name,"subData":[
                          {title: _vm.$t('products.show.sellingPrice'), value: this.$moneyFormat(_vm.item.price) }
                        ],"buttons":[
                          {title: _vm.$t('products.show.modulation'), icon: 'edit', link: _vm.path+'/'+_vm.$route.params.id+'/edit', role: _vm.$user.admin || _vm.$user.role.products_edit} ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('products.show.Background'),"cloumns":{
                            firstCloum: [
                              {icon: 'box-open', title: _vm.$t('products.show.ProductName'), value: _vm.item.name},
                              {icon: 'layer-group', title: _vm.$t('products.show.ProductRating'), value: _vm.item.section, type: 'link', to:'section', link: false},
                              {icon: 'terminal', title: _vm.$t('products.show.ProductCode'), value: _vm.item.code} ],
                            scondCloum: [
                              {icon: 'gift', title: _vm.$t('products.show.ProductType'), value: _vm.item.type == 0 ? _vm.$t('products.show.product') : _vm.$t('products.show.service')},
                              {icon: 'user-tie', title: _vm.$t('products.show.supplier'), value: _vm.item.supplier, type: 'link', to:'supplier', link: true},
                              {icon: 'barcode', title: _vm.$t('products.show.Barcode'), value: _vm.item.barcode} ]
                          }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('products.show.AccountsInformation'),"cloumns":{
                          firstCloum: [
                            {icon: 'user-tie', title: _vm.$t('products.show.supplier'), value: _vm.item.supplier, type: 'link', to:'supplier', link: true},
                            {icon: 'dollar-sign', title: _vm.$t('products.show.cost'), value: this.$moneyFormat(_vm.item.cost) } ],
                          scondCloum: [
                            {icon: 'dollar-sign', title: _vm.$t('products.show.sellingPrice'), value: this.$moneyFormat(_vm.item.price) },
                            {icon: 'dollar-sign', title: _vm.$t('products.show.Profit'), value: this.$moneyFormat(_vm.item.price - _vm.item.cost) } ]
                        }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }